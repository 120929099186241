import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Collage, CollageType } from "../components/collages/Collage";

const ConceptArtPage = () => {
  return (
    <Layout>
      <SEO title="Concept Art" />
      <Collage type={CollageType.ConceptArt} />
    </Layout>
  );
};

export default ConceptArtPage;
